import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import { OutboundLink } from "gatsby-plugin-google-gtag"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="byjos.dev" location={location}>
    <Seo title="Lost&Found" />
    <div>
      <p>
       Hi! So you have found my stuff and want to return it 💗
      </p>
      <p>Please contact me via email lostfound@byjos.dev</p>

    </div>
    <div className="bio-footer">
      <Bio />
    </div>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
